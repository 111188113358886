import GenericService from "../../ApiServices/GenericService";
import { useToast } from "vue-toastification";

const toast = useToast();

const state = {
    cinemas: null,
    cinemas_loading: false,
    selected_cinema_id: null,
    selected_cinema: null
}

const mutations = {
    SET_CINEMAS: (state, payload) => (state.cinemas = payload),
    SET_SELECTED_CINEMA_ID: (state, payload) => (state.selected_cinema_id = payload),
    SET_SELECTED_CINEMA: (state, payload) => (state.selected_cinema = payload),
    CLEAR_CINEMAS_STORE: (state) => {
        state.selected_cinema_id = null;
    }
}

const actions = {
    getAllCinemas: async function({ commit }) {
        try {
            let { data } = await GenericService.GetAllCinemas();
            if(data?.status) {
                commit("SET_CINEMAS", data?.data);
            } else {
                toast.error(data?.message);
                commit("SET_CINEMAS", []);
            }
        } catch(err) {
            toast.error(err?.response?.data?.message || err.message);
        }
    }
}

const getters = {
    GET_SELECTED_CINEMA_ID: state => state.selected_cinema_id,
    GET_ALL_CINEMAS: state => state.cinemas,
    GET_CINEMAS_LOADING: state => state.cinemas_loading,
    GET_SELECTED_CINEMA: state => state.selected_cinema
}

export default {
    state,
    mutations,
    actions,
    getters
}