import Api from "./Api";
import { createQueryString } from "./ApiHelper";

export default {
  GetConcessionCategories(payload) {
    let query = createQueryString(payload);
    return Api().get(`/fnb-db/concession-categories/${query}`);
  },
  GetConcessions(payload) {
    let query = createQueryString(payload);
    return Api().get(`/fnb-db/concessions/${query}`);
  },
  UpdateConcession(payload) {
    return Api().put(`/fnb-db/concessions`, payload);
  },

  UpdateConcessionModifier(payload) {
    return Api().put(`/fnb-db/concessions/modifier`, payload);
  },

  GetConcessionsModifiers(payload) {
    let query = createQueryString(payload);
    return Api().get(`/fnb-db/concessions/modifier/${query}`);
  },
};
