import ConcessionsService from "../../ApiServices/ConcessionsService";
import { useToast } from "vue-toastification";

const toast = useToast();

const state = {
  categories: [],
  concessions: [],
  concessionsModifiers: [],
  selected_category_id: null,
  searchText: "",
  orderby: null,
  selected_consession_id: null,
  concessions_loading: false,
  concessions_mod_loading: false,
  categories_loading: false,
  is_concession_updating: false,
  is_concession_mod_updating: false,
};

const mutations = {
  SET_CONCESSIONS_LOADING: (state, payload) =>
    (state.concessions_loading = payload),
  SET_CONCESSIONS_MOD_LOADING: (state, payload) =>
    (state.concessions_mod_loading = payload),
  SET_CATEGORIES_LOADING: (state, payload) =>
    (state.categories_loading = payload),
  SET_IS_CONCESSION_UPDATING: (state, payload) =>
    (state.is_concession_updating = payload),
  SET_IS_CONCESSION_MOD_UPDATING: (state, payload) =>
    (state.is_concession_mod_updating = payload),
  SET_SEARCH_TEXT: (state, payload) => {
    state.searchText = payload;
  },
  SET_CONCESSION_ID: (state, payload) => {
    state.selected_consession_id = payload;
  },

  SET_CATEGORIES: (state, payload) => {
    state.categories = payload;
  },
  SET_CONCESSIONS: (state, payload) => {
    state.concessions = payload;
  },
  SET_CONCESSIONS_MOD: (state, payload) => {
    state.concessionsModifiers = payload;
  },
  SET_ORDERBY: (state, payload) => {
    state.orderby = payload;
  },
  SET_SELECTED_CATEGORY_ID: (state, payload) => {
    state.selected_category_id = payload;
    if (!payload) {
      state.concessions = [];
    }
  },
  CLEAR_CONCESSIONS_STORE: (state) => {
    state.categories = [];
    state.concessions = [];
    state.concessionsModifiers = [];
    state.selected_category_id = null;
    state.searchText = "";
    state.orderby = null;
    state.concessions_loading = false;
    state.concessions_mod_loading = false;
    state.categories_loading = false;
    state.is_concession_updating = false;
    state.is_concession_mod_updating = false;
  },
};

function GetCinemaIdForAdmin(rootState) {
  rootState = { ...rootState };
  let userDetails = rootState.auth.user_info;
  let selectedCinemaId = rootState.cinemas.selected_cinema_id;

  if (userDetails?.role_id == 5) {
    return selectedCinemaId;
  } else {
    return null;
  }
}

const actions = {
  getAllCategories: async function (
    { state, commit, dispatch, rootState },
    payload
  ) {
    commit("SET_CATEGORIES_LOADING", true);
    let cinema_id = GetCinemaIdForAdmin(rootState);

    try {
      let { data } = await ConcessionsService.GetConcessionCategories({
        cinema_id,
      });

      if (data?.status) {
        commit("SET_CATEGORIES", data.data);
      } else {
        commit("SET_CATEGORIES", []);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      commit("SET_CATEGORIES", []);
    } finally {
      commit("SET_CATEGORIES_LOADING", false);
    }
  },
  getConcessions: async function (
    { state, commit, dispatch, rootState },
    payload
  ) {
    let { selected_category_id, searchText, orderby } = state;
    if (!selected_category_id) {
      return toast.warning("Please select a category");
    }

    let cinema_id = GetCinemaIdForAdmin(rootState);

    commit("SET_CONCESSIONS_LOADING", true);

    try {
      let { data } = await ConcessionsService.GetConcessions({
        search: searchText || null,
        orderby,
        category_id: selected_category_id,
        cinema_id,
      });

      if (data?.status) {
        commit("SET_CONCESSIONS", data.data);
      } else {
        commit("SET_CONCESSIONS", []);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      commit("SET_CONCESSIONS", []);
    } finally {
      commit("SET_CONCESSIONS_LOADING", false);
    }
  },
  getConcessionsModifier: async function (
    { state, commit, dispatch, rootState },
    payload
  ) {
    let {
      selected_category_id,
      searchText,
      orderby,
      selected_consession_id,
    } = state;
    if (!selected_category_id) {
      return toast.warning("Please select a category");
    }

    let cinema_id = GetCinemaIdForAdmin(rootState);

    commit("SET_CONCESSIONS_MOD_LOADING", true);

    try {
      let { data } = await ConcessionsService.GetConcessionsModifiers({
        search: searchText || null,
        orderby,
        category_id: selected_category_id,
        cinema_id,
        selected_consession_id,
      });

      if (data?.status) {
        commit("SET_CONCESSIONS_MOD", data.data);
      } else {
        commit("SET_CONCESSIONS_MOD", []);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err.message);
      commit("SET_CONCESSIONS_MOD", []);
    } finally {
      commit("SET_CONCESSIONS_MOD_LOADING", false);
    }
  },

  updateConcession: async function (
    { state, commit, dispatch, rootState },
    payload
  ) {
    let { concession_id, status } = payload;
    let cinema_id = GetCinemaIdForAdmin(rootState);

    if (!concession_id || !status) {
      return toast.warning("Please send all details while updating concession");
    }

    try {
      commit("SET_IS_CONCESSION_UPDATING", true);
      let { data } = await ConcessionsService.UpdateConcession({
        ...payload,
        cinema_id,
      });

      if (data?.status) {
        toast.success("Updated successfully");
      } else {
        return toast.error("Error while updating: " + data?.message);
      }
    } catch (err) {
      toast.error(
        "Error while updating: " + (err?.response?.data?.message || err.message)
      );
    } finally {
      commit("SET_IS_CONCESSION_UPDATING", false);
    }

    dispatch("getConcessions");
  },

  updateConcessionModifier: async function (
    { state, commit, dispatch, rootState },
    payload
  ) {
    let { modifier_id, modifier_group_id, status } = payload;
    let { selected_category_id, selected_consession_id } = state;
    let cinema_id = GetCinemaIdForAdmin(rootState);

    if (
      !modifier_id ||
      !selected_category_id ||
      !selected_consession_id ||
      !status
    ) {
      return toast.warning("Please send all details while updating concession");
    }

    try {
      commit("SET_IS_CONCESSION_MOD_UPDATING", true);
      let { data } = await ConcessionsService.UpdateConcessionModifier({
        ...payload,
        cinema_id,
        selected_category_id,
        selected_consession_id,
      });

      if (data?.status) {
        toast.success("Updated successfully");
      } else {
        return toast.error("Error while updating: " + data?.message);
      }
    } catch (err) {
      toast.error(
        "Error while updating: " + (err?.response?.data?.message || err.message)
      );
    } finally {
      commit("SET_IS_CONCESSION_MOD_UPDATING", false);
    }

    dispatch("getConcessionsModifier");
  },

  setSelectedCategory: function ({ dispatch, commit }, payload) {
    commit("SET_SELECTED_CATEGORY_ID", payload);
  },
  onChangeSort: async function ({ commit, dispatch }, payload) {
    commit("SET_ORDERBY", payload);
    dispatch("getConcessions");
  },
};

const getters = {
  GET_CATEGORIES: (state) => state.categories,
  GET_CONCESSIONS: (state) => state.concessions,
  GET_CONCESSIONS_MOD: (state) => state.concessionsModifiers,
  GET_SELECTED_CATEGORY_ID: (state) => state.selected_category_id,
  GET_SEARCH_TEXT: (state) => state.searchText,
  GET_ORDERBY: (state) => state.orderby,
  GET_CONCESSIONS_LOADING: (state) => state.concessions_loading,
  GET_CONCESSIONS_MOD_LOADING: (state) => state.concessions_loading,
  GET_CATEGORIES_LOADING: (state) => state.categories_loading,
  GET_IS_CONCESSION_UPDATING: (state) => state.is_concession_updating,
  GET_IS_CONCESSION_MOD_UPDATING: (state) => state.is_concession_updating,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
