<template>
  <div>
    <div type="" class="pb-2 pt-3 pt-md-3 header-bg px-3">
      <h4 v-if="userDetails?.cinema_details?.cinema_name">
        {{userDetails?.cinema_details?.cinema_name}}
      </h4>

      <h1 class="menu-heading">Concessions</h1>
    </div>

    <div class="mx-3 mt-2" v-if="USER_INFO?.role_id === 5 && !GET_SELECTED_CINEMA_ID">
      <fnb-cinema-selection-page></fnb-cinema-selection-page>
    </div>

    <div class="mx-3 mt-2" v-if="(USER_INFO?.role_id && USER_INFO?.role_id !== 5) || (USER_INFO?.role_id === 5 && GET_SELECTED_CINEMA_ID)">
      <fnb-categories v-if="!GET_SELECTED_CATEGORY_ID"></fnb-categories>
      <fnb-items v-else></fnb-items>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FnbCategories from "./FnbMenuPages/FnbCategories.vue";
import FnbItems from "./FnbMenuPages/FnbItems.vue";
import FnbCinemaSelection from "./FnbMenuPages/FnbCinemaSelectionPage.vue"
import FnbCinemaSelectionPage from './FnbMenuPages/FnbCinemaSelectionPage.vue';

export default {
  name: "Menu",
  components: {
    FnbCategories,
    FnbItems,
    FnbCinemaSelectionPage,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters([
      "GET_SELECTED_CATEGORY_ID",
      "USER_INFO",
      "GET_SELECTED_CINEMA_ID",
    ]),
    ...mapGetters({
      userDetails: "USER_INFO"
    })
  },
  methods: {},
};
</script>
