<template>
  <div id="app container-fluid">
    <router-view />
  </div>
</template>
<script>
import { TokenService } from "./ApiServices/tokenService";

export default {
  name: "App",
  mounted: function() {
    let token = TokenService.getToken();
    if(!token) {
      this.$router.replace('/login');
    }
  }
}
</script>
