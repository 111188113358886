import axios from 'axios';
import { BASEURL } from "../config/index";
import { TokenService } from "./tokenService";
import router from "../router";

const createAxiosInstance = () => {
    const instance = axios.create({
        baseURL: `${BASEURL}`
    })

    const token = TokenService.getToken();
    if (token) {
        instance.defaults.headers.common["Authorization"] = token;
    }

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (error.response && error.response.status === 403) {
            TokenService.removeToken();
            router.push("/login");
        }
        return Promise.reject(error);
    });

    return instance;
}

export default createAxiosInstance;
