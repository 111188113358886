<template>
  <div>
    <!-- <h2 class="mb-2 concession-heading">Categories</h2> -->

    <div v-if="GET_CATEGORIES_LOADING" v-loading="GET_CATEGORIES_LOADING" class="mt-5"></div>

    <div class="my-2">
        <h2>Categories</h2>
    </div>

    <div class="mt-3" v-if="USER_INFO?.role_id === 5 && GET_SELECTED_CINEMA_ID">
        <button class="btn btn-success my-3" @click.stop="clearSelectedCinema()">
          Back
        </button>
    </div>

    <div v-if="GET_CATEGORIES?.length > 0 && !GET_CATEGORIES_LOADING">
        <button
            v-for="(category, categoryIndex) in GET_CATEGORIES"
            :key="categoryIndex"
            class="btn btn-primary my-2" 
            @click.stop="setSelectedCategory(category.Id)"
        >
            {{ category.Name }}
        </button>
    </div>

    <div v-if="GET_CATEGORIES?.length == 0 && !GET_CATEGORIES_LOADING" class="d-flex justify-content-center align-items-center">
        <h3>No Categories Found</h3>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FnbCategories",
  data: function () {
    return {};
  },
  computed: {
      ...mapGetters([
            "GET_CATEGORIES",
            "GET_SELECTED_CATEGORY_ID",
            "GET_CATEGORIES_LOADING",
            "USER_INFO",
            "GET_SELECTED_CINEMA_ID"
      ])
  },
  mounted: function () {
    this.getAllCategories();
  },
  methods: {
      ...mapActions([
          "getAllCategories",
          "setSelectedCategory"
      ]),
      clearSelectedCinema() {
          this.$store.commit("SET_SELECTED_CINEMA_ID", null)
          this.$store.commit("SET_SELECTED_CINEMA", null)
      }
  }
};
</script>
