<template>
  <div>
    <h2 class="mb-3">Select Cinema</h2>

    <div v-if="GET_CINEMAS_LOADING" v-loading="GET_CINEMAS_LOADING" class="mt-5"></div>

    <div v-if="GET_ALL_CINEMAS?.length > 0 && !GET_CINEMAS_LOADING">
        <button
            v-for="(cinema, cinemaIndex) in GET_ALL_CINEMAS"
            :key="cinemaIndex"
            class="btn btn-primary my-2" 
            @click.stop="setSelectedCinema(cinema)"
        >
            {{ cinema.cinema_name }}
        </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex"

export default {
  name: "FnbCinemaSelection",
  data: function() {
      return {}
  },
  computed: {
      ...mapGetters([
            "GET_SELECTED_CINEMA_ID",
            "GET_ALL_CINEMAS",
            "GET_CINEMAS_LOADING",
      ])
  },
  mounted: function() {
      this.getAllCinemas();
  },
  methods: {
      ...mapActions([
          "getAllCinemas",
      ]),
      setSelectedCinema(cinema) {
          this.$store.commit("SET_SELECTED_CINEMA_ID", cinema.cinema_id)
          this.$store.commit("SET_SELECTED_CINEMA", cinema)
      }
  }
};
</script>
