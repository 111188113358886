<template>
  <div class="row justify-content-center mx-0">
    <div class="col">
      <div class="card bg-white shadow border-0 login-card">
        <div class="card-body">
          <div class="text-center text-muted mb-4">
            <img src="../../public/img/brand/logo192.png" width="60" alt="" />
            <h3>Sign In</h3>
          </div>
          <form role="form" @submit.prevent="handleLogin">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Cinema ID"
              addon-left-icon="ni ni-box-2"
              v-model="model.email"
              :valid="true"
              :required="true"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>
            <!-- 
            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox> -->
            <div class="text-center">
              <button type="submit" class="btn btn-primary my-4">
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapActions } from "vuex";

export default {
  name: "login",
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["Login"]),
    handleLogin: function (event) {
      console.log("handleLogin called: ", event.target);

      if (!this.model.email || !this.model.password) {
        return this.toast.warning("Please enter all the details");
      }

      this.Login({
        email: this.model.email,
        password: this.model.password,
      });
    },
  },
};
</script>
<style></style>
