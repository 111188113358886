import AuthService from "../../ApiServices/AuthService";
import { TokenService } from "../../ApiServices/tokenService";
import router from "../../router";
import { useToast } from "vue-toastification"

const toast = useToast();

const state = {
    user_info: null
};

const mutations = {
    SET_USER_DETAILS: (state, payload) => {
        state.user_info = payload;
    }
}

const actions = {
    Login: async function({ dispatch, commit, state }, { email, password }) {
        try {
            let { data } = await AuthService.Login({
                email, password
            });

            if(data.status && data?.data?.user && data?.data?.token) {
                commit("SET_USER_DETAILS", data?.data?.user);
                TokenService.saveToken(data?.data?.token);
                router.push("/menu")
            } else {
                commit("SET_USER_DETAILS", null)
            }
        } catch(err) {
            toast.error(err?.response?.data?.message || err?.message);
            commit("SET_USER_DETAILS", null)
        }
    },
    getUserDetails: async function({ dispatch, commit, state }) {
        let token = TokenService.getToken();
        if(token) {
            try {
                const { data } = await AuthService.GetUserDetails();
                if(data.status && data.data) {
                    commit("SET_USER_DETAILS", data.data);
                } else {
                    TokenService.removeToken()
                    router.push("/login");
                }
            } catch(err) {
                // toast.error(err?.response?.data?.message || err?.message);
                TokenService.removeToken()
                router.push("/login");
            }
        } else {
            router.push("/login");
        }
    },
    Logout: function({ commit }) {
        TokenService.removeToken()
        commit("SET_USER_DETAILS", null)
        commit("CLEAR_CONCESSIONS_STORE");
        router.push("/login");
    }
}

const getters = {
    USER_INFO: state => state.user_info
}

export default {
    state,
    mutations,
    actions,
    getters
  };