<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <!-- <div @click="hideSidebar">
      <i class="ni ni-user-run"></i>
    </div> -->
    <!-- <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <div class="form-group mb-0">
        <base-input
          placeholder="Search"
          class="input-group-alternative"
          alternative=""
          addon-right-icon="fas fa-search"
        >
        </base-input>
      </div>
    </form> -->
    <ul class="navbar-nav align-items-center d-none d-md-flex ml-auto">
      <li class="nav-item dropdown">
        <!-- <base-dropdown class="nav-link pr-0">
          <template v-slot:title>
            <div class="media align-items-center">
              <div class="media-body ml-2 d-none d-lg-block cursor-pointer">
                <span class="mb-0 text-sm font-weight-bold">Hello, {{ userDetails?.first_name }}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Welcome!</h6>
          </div>
          <div class="dropdown-item" @click="handleLogout">
            <i class="ni ni-user-run"></i>
            <span>Logout</span>
          </div>
        </base-dropdown> -->
        <el-dropdown trigger="click" type="primary">
          <span class="mb-0 text-sm font-weight-bold"
            >Hello, User</span
          >
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <h6 class="text-overflow m-0">Welcome!</h6></el-dropdown-item
              >
              <el-dropdown-item @click="handleLogout"
                ><span>Logout</span></el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
  </base-nav>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      userDetails: "USER_INFO"
    })
  },
  methods: {
    toggleSidebar() {
      console.log("function called");
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleLogout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>
