<template>
  <div>
    <!-- <h2 class="concession-heading">Concessions</h2> -->
    <div class="">
      <button
        class="btn btn-primary my-2"
        @click.stop="setSelectedCategory(null)"
      >
        Back
      </button>
    </div>

    <div
      v-if="GET_CONCESSIONS_LOADING"
      v-loading="GET_CONCESSIONS_LOADING"
      class="mt-5"
    ></div>

    <div
      class="table-responsive"
      v-if="GET_CONCESSIONS?.length > 0 && !GET_CONCESSIONS_LOADING"
    >
      <base-table
        class="table align-items-center table-flush"
        thead-classes="thead-light"
        tbody-classes="list"
        :data="GET_CONCESSIONS"
      >
        <template v-slot:columns>
          <th>Item Name</th>
          <th>Status</th>
          <th></th>
          <th>Actions</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.Description
                }}</span>
              </div>
            </div>
          </th>
          <td>
            <badge class="badge-dot mr-4" :type="getStatusType(row.item)">
              <i :class="`bg-${getStatusType(row.item)}`"></i>
              <span class="status">{{ getStatusMessage(row.item) }}</span>
            </badge>
          </td>
          <td class="budget">
            <el-switch
              v-model="row.item.is_active"
              active-value="Y"
              inactive-value="N"
              @change="
                (val) =>
                  handleItemStatusChange(
                    val == 'Y' ? 1 : 2,
                    row.item.concession_id
                  )
              "
            ></el-switch>
          </td>

          <td>
            <el-dropdown trigger="click" type="primary">
              <i class="fas fa-ellipsis-v"></i>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="handleItemStatusChange(1, row.item.concession_id)"
                    >Available</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click="handleItemStatusChange(2, row.item.concession_id)"
                    >Unavailable</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click="handleItemStatusChange(3, row.item.concession_id)"
                    >Unavailable Until Next Day</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </td>
          <td>
            <button
              @click="handleEditModifier(row.item.Id, row.item.Description)"
              class="btn btn-outline-primary my-2"
            >
              Modifiers
            </button>
          </td>
        </template>
      </base-table>
    </div>
    <hr />
    <div
      v-if="GET_CONCESSIONS_MOD_LOADING"
      v-loading="GET_CONCESSIONS_MOD_LOADING"
      class="mt-5"
    ></div>

    <div
      class="table-responsive"
      v-if="
        GET_CONCESSIONS_MOD?.length > 0 &&
        !GET_CONCESSIONS_MOD_LOADING &&
        showModifiers == true
      "
    >
      <h4 class="mb-2">
        <badge class="btn btn-info">
          MODIFIERS ({{ this.concessionName }})
        </badge>
      </h4>
      <base-table
        class="table align-items-center table-flush"
        thead-classes="thead-light"
        tbody-classes="list"
        :data="GET_CONCESSIONS_MOD"
      >
        <template v-slot:columns>
          <th>Item Name</th>
          <th>Status</th>
          <th></th>
          <th>Actions</th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.Description
                }}</span>
              </div>
            </div>
          </th>
          <td>
            <badge
              class="badge-dot mr-4"
              :type="getStatusTypeModifier(row.item)"
            >
              <i :class="`bg-${getStatusTypeModifier(row.item)}`"></i>
              <span class="status">{{
                getStatusMessageModifier(row.item)
              }}</span>
            </badge>
          </td>
          <td class="budget">
            <el-switch
              v-model="row.item.is_active"
              active-value="Y"
              inactive-value="N"
              @change="
                (val) =>
                  handleItemStatusChangeModifier(
                    val == 'Y' ? 1 : 2,
                    row.item.modifier_id,
                    row.item.modifier_group_id
                  )
              "
            ></el-switch>
          </td>

          <td>
            <el-dropdown trigger="click" type="primary">
              <i class="fas fa-ellipsis-v"></i>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    @click="
                      handleItemStatusChangeModifier(
                        1,
                        row.item.modifier_id,
                        row.item.modifier_group_id
                      )
                    "
                    >Available</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click="
                      handleItemStatusChangeModifier(
                        2,
                        row.item.modifier_id,
                        row.item.modifier_group_id
                      )
                    "
                    >Unavailable</el-dropdown-item
                  >
                  <!-- <el-dropdown-item
                    @click="handleItemStatusChange(3, row.item.concession_id)"
                    >Unavailable Until Next Day</el-dropdown-item
                  > -->
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </td>
        </template>
      </base-table>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FnbItems",
  data: function () {
    return {
      showModifiers: false,
      concessionName: "",
    };
  },
  computed: {
    ...mapGetters([
      "GET_CONCESSIONS",
      "GET_CONCESSIONS_MOD",
      "GET_SELECTED_CATEGORY_ID",
      "GET_SEARCH_TEXT",
      "GET_ORDERBY",
      "GET_CONCESSIONS_LOADING",
      "GET_CONCESSIONS_MOD_LOADING",
      "GET_IS_CONCESSION_UPDATING",
    ]),
  },
  mounted: function () {
    this.$store.commit("SET_SEARCH_TEXT", "");
    this.$store.commit("SET_ORDERBY", null);
    this.$store.dispatch("getConcessions");
  },
  methods: {
    ...mapActions([
      "updateConcession",
      "onChangeSort",
      "setSelectedCategory",
      "updateConcessionModifier",
    ]),
    onChangeSearch: function (event) {
      this.$store.commit("SET_SEARCH_TEXT", event.target.value);
    },
    getStatusTypeModifier: function (item) {
      item = { ...item };
      if (item.is_active === "Y") {
        return "info";
      }
      // else if (
      //         item.is_active === "N"
      //         // &&
      //         //         item.disabled_till_next_day === "Y"
      //       ) {
      //         return "warning";
      //       }
      else if (
        item.is_active === "N"
        // &&
        //         item.disabled_till_next_day === "N"
      ) {
        return "danger";
      }
    },
    getStatusMessageModifier: function (item) {
      item = { ...item };
      if (item.is_active === "Y") {
        return "Available";
      }
      // else if (
      //         item.is_active === "N"
      //         // &&
      //         //         item.disabled_till_next_day === "Y"
      //       ) {
      //         return "Unavailable Until Next Day";
      //       }
      else if (
        item.is_active === "N"
        // &&
        //         item.disabled_till_next_day === "N"
      ) {
        return "Unavailable";
      }
    },
    getStatusType: function (item) {
      item = { ...item };
      if (item.is_active === "Y") {
        return "info";
      } else if (
        item.is_active === "N" &&
        item.disabled_till_next_day === "Y"
      ) {
        return "warning";
      } else if (
        item.is_active === "N" &&
        item.disabled_till_next_day === "N"
      ) {
        return "danger";
      }
    },

    getStatusMessage: function (item) {
      item = { ...item };
      if (item.is_active === "Y") {
        return "Available";
      } else if (
        item.is_active === "N" &&
        item.disabled_till_next_day === "Y"
      ) {
        return "Unavailable Until Next Day";
      } else if (
        item.is_active === "N" &&
        item.disabled_till_next_day === "N"
      ) {
        return "Unavailable";
      }
    },
    handleEditModifier: function (concession_id, name) {
      this.concessionName = name;
      this.showModifiers = true;
      this.$store.commit("SET_CONCESSION_ID", concession_id);
     
     setTimeout(() => {
       this.$store.dispatch("getConcessionsModifier");
     }, 500);
    },
    handleItemStatusChange: function (value, concession_id) {
      let statusMessage;

      switch (value) {
        case 1:
          statusMessage = "active";
          break;
        case 2:
          statusMessage = "inactive";
          break;
        case 3:
          statusMessage = "inactive_till_next_day";
          break;
      }

      this.updateConcession({
        concession_id,
        status: statusMessage,
      });
    },
    handleItemStatusChangeModifier: function (
      value,
      modifier_id,
      modifier_group_id
    ) {
      let statusMessage;

      switch (value) {
        case 1:
          statusMessage = "active";
          break;
        case 2:
          statusMessage = "inactive";
          break;
        case 3:
          statusMessage = "inactive_till_next_day";
          break;
      }

      this.updateConcessionModifier({
        modifier_id,
        modifier_group_id,
        status: statusMessage,
      });
    },
  },
};
</script>
