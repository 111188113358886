import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import Toast from "vue-toastification";
import store from "./store";

import "element-plus/lib/theme-chalk/index.css";
import "vue-toastification/dist/index.css";
import "./assets/css/style.css";

const appInstance = createApp(App);
appInstance.use(router);
appInstance.use(store);
appInstance.use(Toast);
appInstance.use(ArgonDashboard);
appInstance.mount("#app");
