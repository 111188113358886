<template>
  <div class="pt-5">
    <h1 class="p-3">Coming Soon</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
    };
  },
  methods: {
    toggleSidebar() {
      console.log("function called");
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleLogout() {
      this.$store.dispatch("Logout");
    },
  },
};
</script>
