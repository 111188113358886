const TOKEN_KEY = 'access_token'
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/
const TokenService = {
  getToken() {
    return window.localStorage.getItem(TOKEN_KEY)
  },

  saveToken(accessToken) {
    window.localStorage.setItem(TOKEN_KEY, accessToken)
  },

  removeToken() {
    window.localStorage.removeItem(TOKEN_KEY)
  }
}

export {
  TokenService
}
